import MainRoutes from './Routes/MainRoute';

function App() {
  return (
    <>
    <MainRoutes />
    </>
  );
}

export default App;
