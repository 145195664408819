import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import NotFound from '../NotFound/NotFound';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import AboutUs from '../AboutUs/AboutUs';
import ContactUs from '../Contact/ContactUs';
import Faqs from '../Faqs/Faqs';
import MyAddress from '../MyAddress/MyAddress';
import MyProfile from '../MyProfile/MyProfile';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Wishlist from '../Wishlist/Wishlist';
import Login from '../Login/Login';
import Cart from '../Cart/Cart';
import Checkout from '../Checkout/Checkout';


function MainRoutes() {

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path="/Faqs" element={<Faqs />} />
          <Route exact path="/MyAddress" element={<MyAddress />} />
          <Route exact path="/MyProfile" element={<MyProfile />} />
          <Route exact path="/Wishlist" element={<Wishlist />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Cart" element={<Cart />} />
          <Route exact path="/Checkout" element={<Checkout />} />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default MainRoutes;