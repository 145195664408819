import React from 'react'

function MyAddress() {
  return (
    <div>
      <main className="main__content_wrapper">
  {/* Start breadcrumb section */}
  <div className="breadcrumb__section breadcrumb__bg">
    <div className="container">
      <div className="row row-cols-1">
        <div className="col">
          <div className="breadcrumb__content text-center">
            <ul className="breadcrumb__content--menu d-flex justify-content-center">
              <li className="breadcrumb__content--menu__items"><a href="index.html">Home</a></li>
              <li className="breadcrumb__content--menu__items"><span>My Account</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End breadcrumb section */}
  {/* my account section start */}
  <section className="my__account--section section--padding">
    <div className="container">
      <div className="my__account--section__inner border-radius-10 d-flex">
        <div className="account__left--sidebar">
          <h2 className="account__content--title h3 mb-20">My Profile</h2>
          <ul className="account__menu">
            <li className="account__menu--list"><a href="my-account.html">Dashboard</a></li>
            <li className="account__menu--list active"><a href="my-account-2.html">Addresses</a></li>
            <li className="account__menu--list"><a href="wishlist.html">Wishlist</a></li>
            <li className="account__menu--list"><a href="login.html">Log Out</a></li>
          </ul>
        </div>
        <div className="account__wrapper">
          <div className="account__content">
            <h2 className="account__content--title h3 mb-20">Addresses</h2>
            <button className="new__address--btn primary__btn mb-25" type="button">Add a new address</button>
            <div className="account__details two">
              <h3 className="account__details--title h4">Default</h3>
              <p className="account__details--desc">Admin <br /> Dhaka <br /> Dhaka 12119 <br /> Bangladesh</p>
              <a className="account__details--link" href="my-account-2.html">View Addresses (1)</a>
            </div>
            <div className="account__details--footer d-flex">
              <button className="account__details--footer__btn" type="button">Edit</button>
              <button className="account__details--footer__btn" type="button">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* my account section end */}
  {/* Start feature section */}
  <section className="feature__section section--padding pt-0">
    <div className="container">
      <div className="feature__inner d-flex justify-content-between">
        <div className="feature__items d-flex align-items-center">
          <div className="feature__icon">  
            <img src="assets/img/other/feature1.webp" alt="img" />
          </div>
          <div className="feature__content">
            <h2 className="feature__content--title h3">Free Shipping</h2>
            <p className="feature__content--desc">Free shipping over $100</p>
          </div>
        </div>
        <div className="feature__items d-flex align-items-center">
          <div className="feature__icon ">  
            <img src="assets/img/other/feature2.webp" alt="img" />
          </div>
          <div className="feature__content">
            <h2 className="feature__content--title h3">Support 24/7</h2>
            <p className="feature__content--desc">Contact us 24 hours a day</p>
          </div>
        </div>
        <div className="feature__items d-flex align-items-center">
          <div className="feature__icon">  
            <img src="assets/img/other/feature3.webp" alt="img" />
          </div>
          <div className="feature__content">
            <h2 className="feature__content--title h3">100% Money Back</h2>
            <p className="feature__content--desc">You have 30 days to Return</p>
          </div>
        </div>
        <div className="feature__items d-flex align-items-center">
          <div className="feature__icon">  
            <img src="assets/img/other/feature4.webp" alt="img" />
          </div>
          <div className="feature__content">
            <h2 className="feature__content--title h3">Payment Secure</h2>
            <p className="feature__content--desc">We ensure secure payment</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End feature section */}
</main>
    </div>
  )
}

export default MyAddress
